import React from 'react'
import pluralize from 'pluralize'
import { getColorForPortion } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/styles'
import { Text } from '@revolut/ui-kit'
import {
  goalsOwnerToEntityNameMap,
  makeMissingEntitiesText,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'

interface Props {
  goalsOwner: GoalsOwner
  totalCount: number
  withGoalsCount: number
}

export const GoalsLabel = ({ goalsOwner, withGoalsCount, totalCount }: Props) => {
  const color = getColorForPortion(withGoalsCount / totalCount)
  const description = `of ${totalCount} ${pluralize(
    goalsOwnerToEntityNameMap[goalsOwner],
    withGoalsCount,
  )} ${pluralize('have', withGoalsCount)} provided goals`
  const noEntitiesDescription = makeMissingEntitiesText(goalsOwner)

  if (totalCount === 0) {
    return <Text>{noEntitiesDescription}</Text>
  }

  return (
    <>
      <Text color={color}>{withGoalsCount}</Text> <Text>{description}</Text>
    </>
  )
}
